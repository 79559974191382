@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Bodoni+Moda&family=Cormorant+Garamond:wght@300;400;500&family=Courgette&family=El+Messiri&family=Poppins:wght@100;400;600&family=Raleway:wght@100;300;500&display=swap");
*,*::after,*::before {
  box-sizing: border-box;
}
:root {
  --purple: #6200b3;
  --fancy-font-family: 'Raleway', serif;
  --caps-font-family: 'Bebas Neue', serif;
  --cursive-font-family: 'Courgette', 'cursive';
  --messiri-font-family: 'El Messiri', sans-serif;
  --roman-font-family: 'Cormorant Garamond', sans-serif;
  --art-font-family: 'Poppins', sans-serif;
  --title-font-size: 1.3em;
  /* property: var(--fancy-font-family) */
}
body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  overflow-x: hidden;
 
}
.container *{
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

nav {
  z-index: 100;
  position: relative;
}
.mobile-title {
  display: flex;
  justify-content: space-between;
  margin: 0 1em;
}
.mobile-title h1 {
  font-family: var(--caps-font-family);
  color: var(--purple);
  font-size: 1.7em;
  font-weight: 300;
}
.mobile-title button {
  position: absolute;
  right: 0%;
  top: 12%;
  margin: 1em;
  z-index: 9999;

  background-color: transparent;
  border: none;
  font-size: 1.2em;
  cursor: pointer;
  color: black;
}
.mobile-container  {
  position: fixed;
  top: 0%;
  right: 0%;
  margin: 5em 3em;

}
.mobile-list-cl ul {
  display: flex;
  flex-direction: column;
  
  
  transform: translateX(150%);
  transition: 0.7s ease-in-out;
}
.mobile-list-op ul{
  transform: translateX(0%);
  transition: 0.5s ease-in-out;
}
.mobile-list-op ul li, .mobile-list-cl ul li {
  padding: .5em;
  font-size: 1.7em;
  text-align: right;
  list-style-type: none;

  color: black;
  font-family: var(--caps-font-family);
  letter-spacing: 0.1em;
  text-decoration: none;
}
.mobile-list-op ul a, .mobile-list-cl ul a {
  text-decoration: none;
 
}
.mobile-list-op ul li, .mobile-list-cl ul li {
  text-decoration: none;
  transition: 0.5s ease;
}
.mobile-list-op ul li:hover, .mobile-list-cl ul li:hover {
  text-decoration: underline;
}

@media screen and (min-width: 1000px) {
  .mobile-title button {
    display: none;
  }
  nav {
    margin-left: 1em;
    margin-right: 1em;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .mobile-container {
    position: static;
    margin: 0;
  }
  .mobile-list-cl ul {
    transform: none;
  }
  .mobile-list-op ul,.mobile-list-cl ul {
    display: flex;
    flex-direction: row;
  }
}

.container-dk {
  opacity: 0.3;
  transition: 0.5s ease-in-out;
}
.container {
  transition: 0.5s ease-in-out;
}

.landing-wrapper {
  margin-top: 4em;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.landing-wrapper h1 {
  font-family: var(--fancy-font-family);
  font-weight: 200;
  font-size: 3.5em;
  text-align: center;
  margin-top: 0.2em;
  margin-bottom: 0.5em;
}
.landing-wrapper h2 {
  font-family: var(--caps-font-family);
  font-size: 1.5em;
  letter-spacing: 0.2em;
  font-weight: lighter;
  margin-bottom: 1em;
}
.landing-wrapper button {
  margin-top: 1em;
  background-color: transparent;
  border: none;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-size: 1em;
  cursor: pointer;
  transition: 0.4s;
  margin-bottom: 1em;
  color: var(--purple);
  font-family: var(--fancy-font-family);
}
.landing-wrapper button:hover,.landing-wrapper button:active {
  color: black;
}
#dots {
  margin-top: 2em;
  transform: scale(2);
  color: black;
  margin-bottom: 1em;
}
.sites-container {
  margin-top: 2.5em;
  min-width: 7em;
  max-width: 10em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  transform: scale(1.5)
}
.sites-container * {
  cursor: pointer;
  color: black;
}
#ig {
  transform: scale(1.4);
}
#email {
  transform: scale(1.3);
}
#messenger {
  transform: scale(1.15);
}
#fb {
  transform: scale(1.1);
}
@media screen and (min-width: 1000px) {
  .landing-wrapper h1 {
    font-size: 6.5em;
  }
  .landing-wrapper h2 {
    font-size: 2em;
  }
}

.art-container {
  max-width: 75%;
  margin-left: auto;
  margin-right: auto;
}
.artwork {
  background-color: #e9ecef;
  margin-bottom: 3em;
  border-radius: 1.2em;
  box-shadow: 5px 5px 15px -7px #000000;
  font-family: var(--art-font-family);
}
.artwork p, section {
  text-align: left;
}

#title {
  font-weight: 400;
}
#price, #status, #status-sold {
  font-weight: 600;
}
#status {
  color: #52b788;
  padding-left: 0.5em;
}
#status-sold {
  color: #9d0208;
  padding-left: 0.3em;
}

.artwork section {
  padding: 0em .8em;
  font-size: 1.2em;
}
.artwork section p:nth-of-type(1) {
  margin-bottom: 0;
}

.artwork img {
  max-width: 100%;
  border-top-left-radius: 1.2em;
  border-top-right-radius: 1.2em;
}
#price, #status, #status-sold {
  display: inline-block;
}
#price:before {
  content: 'C$'
}
@media screen and (min-width: 1000px) {
  .art-container {
    max-width: initial;
    margin: 0 2em;
  }
  .artwork-container  {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 2em;
  }
  .artwork {
    flex-basis: 40em;
  }
  .artwork section {
    font-size: 1.4em;
  }
  
}



#pg-title {
  text-align: center;
  font-family: var(--roman-font-family);
  font-size: 2.2em;
  
}
.about-container {
  margin: 1em;
  text-align: center;
}
.about-container .artists-info {
  font-family: var(--cursive-font-family);
  font-weight: 100;
  text-transform: capitalize;
  font-size: 2em;
  text-align: center;
}
.artists-info h5 {
  margin-bottom: -1em;
}
.about-container img {
  max-width: 40%;
  border-radius: 50%;
}
.about-container .artist-desc p {
  text-align: justify;
  font-family: var(--roman-font-family);
  font-weight: 500;
  font-size: 1.5em;
  padding-right: 0.5em;
}
#break {
  color: var(--purple);
  
}
.artist-appear {
  display: none;
}
.developer {
  font-family: 'Trebuchet MS', sans-serif;
  margin-top: 5em;
  text-align: center;
  display: flex;
  flex-direction: column;
}
.developer section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 2em;
}
.developer section p {
  margin-right: .5em;
}
.developer section div > *{
  transform: scale(1.4);
  margin: 0.5em;
}
.dev-icons >:nth-of-type(1) {
  color: #00d8ff;
}
.dev-icons >:nth-of-type(2) {
  color: #3c873a;
}
.dev-icons >:nth-of-type(3) {
  color: #2965f1;
}
.dev-icons >:nth-of-type(4) {
  color: #FFA611;
}


@media screen and (min-width: 1000px) {
  .about-container {
    display: flex;
    gap: 3em;
  }
  .about-container img {
    max-width:40%;
    align-self: center;
  }
  .about .about-container .artists-info {
    display: none;
  }
  .about .artist-appear {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3em;
    font-weight: lighter;
    text-align: center;
    text-transform: capitalize;
    font-family: var(--cursive-font-family);
    font-weight: lighter;

  }
  .about .artist-appear > * {
    padding: 0.3em;
  }

}

.contact-container {
  width: 100%;
  height: 100%;
  
}
.contact-container #pg-title {
  margin-bottom: 1em;
}
.form-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}
.form-container form p label {
  font-size: 1.2em;
  font-family: var(--fancy-font-family);
}
input[type='text'], input[type='email'], textarea {
  padding: 0.8em 1em;
  min-width: 20em;
  margin: 1em 0;
  border-radius: 2px;
  border: 1px solid var(--purple);
  box-shadow: 2px 2px 2px -2px #000000;
}
.form-container form button {
  margin-top: 2em;
  margin-bottom: 2em;
  padding: .8em 3em;
  background-color: var(--purple);
  border: 1px solid var(--purple);
  border-radius: 10px;
  font-family: var(--fancy-font-family);
  text-transform: capitalize;
  font-size: 1.2em;
  cursor: pointer;
  box-shadow: 2px 2px 2px -2px #000000;
  color: white;
  transition: 0.4s;
}
.form-container form button:hover,.form-container form button:active{
  background-color: white;
  color: var(--purple);
}



#coming-soon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3em;
  margin-top: 3em;
}

footer {
  font-family: var(--title-font-size);
  /* position: absolute;
  bottom: 0%; */
  margin-top: 7em;
  font-size: 1em;
  
}

.construct p{
  margin-top: 15em;
  margin-bottom: 5em;
  text-align: center;
  transform: scale(3.5);
  color: #c2a90f ;
  font-family: var(--caps-font-family);
}